import Image from 'components/Image'
import Overline from 'components/Overline'
import Link from 'components/Link'
import { screens } from 'lib/constants'

export default function ThreeUp({ filter, slots, articles }) {
  return (
    <div className="-mx-15 s:mx-0 mb-50">
      <div className="overflow-x-auto s:overflow-visible hide-scrollbar whitespace-nowrap s:whitespace-normal">
        <div className="flex pl-15 s:pl-0 s:-mx-8 l:-mx-15 xl:-mx-25">
          {articles.map((item, i) => {
            const slot = slots?.[i]
            const article = slot?.article ?? item
            const tag = slot?.filter ?? article?.primaryTag
            return (
              <div
                key={article._id}
                className="w-230 s:w-1/3 whitespace-normal flex-none pr-15 s:px-8 l:px-15 xl:px-25"
              >
                {article?.featuredImage ? (
                  <Link link={article.link} tabIndex="-1">
                    <Image
                      image={article.featuredImage}
                      width={230}
                      height={230}
                      className="rounded mb-20"
                      sizes={`230px, (min-width: ${screens.s}) 33vw`}
                    />
                  </Link>
                ) : null}
                <div className="space-y-15">
                  {!filter && tag ? (
                    <Overline underline={false}>
                      <Link link={tag.link}>{tag.title}</Link>
                    </Overline>
                  ) : null}
                  <h3 className="text-20 s:text-22 font-medium leading-130">
                    <Link link={article.link}>{article.title}</Link>
                  </h3>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
