import Image from 'components/Image'
import Overline from 'components/Overline'
import TextButton from 'components/TextButton'
import ThreeUp from 'modules/ThreeUp'
import Link from 'components/Link'
import useMedia from 'use-media'
import { screens } from 'lib/constants'

export default function FullWidthFeatureThreeUp({ filter, slots, articles }) {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const feature = slots?.[0]?.article ?? articles?.[0]
  const threeUp = articles?.slice(1) ?? []

  if (!feature && !threeUp.length) return null

  return (
    <div>
      <div className="mb-50 s:flex l:items-center s:-mx-8 l:-mx-15 xl:-mx-25">
        {feature?.featuredImage ? (
          <div className="s:w-7/12 l:w-2/3 s:px-8 l:px-15 xl:px-25">
            <Link link={feature.link} tabIndex="-1">
              <Image
                image={feature.featuredImage}
                width={345}
                height={195}
                sizes={`100vw, (min-width: ${screens.s}) 70vw, (min-width: ${screens.l}) 60vw`}
                className="rounded mb-20"
              />
            </Link>
          </div>
        ) : null}
        <div className="s:w-5/12 l:w-1/3 -order-1 s:px-8 l:px-15 xl:px-25">
          {!filter && feature?.primaryTag?.title ? (
            <Overline className="mb-15 l:mb-30" underline={false}>
              <Link link={feature.primaryTag.link}>{feature.primaryTag.title}</Link>
            </Overline>
          ) : null}
          <h3 className="text-20 s:text-24 m:text-28 xl:text-32 font-medium leading-130">
            <Link link={feature.link}>{feature.title}</Link>
          </h3>
          {feature?.excerpt ? (
            <p className="text-15 l:text-16 xl:text-20 mt-10 l:mt-20">{feature.excerpt}</p>
          ) : null}
          <TextButton
            className="mt-10 l:mt-40"
            size={isScreenLarge ? 'large' : 'compact'}
            chevron
            link={feature.link}
            tabIndex="-1"
          >
            Read More
          </TextButton>
        </div>
      </div>
      <ThreeUp filter={filter} slots={slots?.slice(1) ?? []} articles={threeUp} />
    </div>
  )
}
